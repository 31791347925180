<template>
  <div class="about__container container">
    <h2>{{ $t('1_title') }}</h2>
    <h5 :class="locale" v-html="$t('30_about_title')"></h5>
    <p>{{ $t('31_about_text') }}</p>
    <div class="d-flex">
      <span>#Success</span>
      <span>#Marketing</span>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'About',

  computed: mapGetters({
    locale: 'lang/locale',
  }),
}
</script>

<style lang="scss">
.about__container {
  padding-top: 200px;
  h2 {
    font-family: 'Ritzy', sans-serif;
    font-size: 150px;
    font-weight: 400;
    line-height: 197px;
    letter-spacing: 0.17em;
    text-align: left;
    color: #0000FF;
  }
  h5 {
    font-family: 'Ritzy', sans-serif;
    font-size: 45px;
    font-weight: 400;
    line-height: 59px;
    letter-spacing: 0.15em;
    text-align: left;
    color: #0000FF;
    &.ru {
      font-family: "Atiba", sans-serif;
      span {
        font-family: Ritzy, sans-serif;
        font-size: 45px;
      }
    }
  }
  p {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 200;
    line-height: 24px;
    letter-spacing: 0.03em;
    text-align: left;
    max-width: 525px;
    width: 100%;
    color: #fff;
    margin-top: 50px;
  }
  .d-flex {
    margin-top: 50px;
    span {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.03em;
      text-align: left;
      color: #fff;
      &:last-child {
        margin-left: 24px;
      }
    }
  }
}
</style>
<template>
  <div class="grid__container container position-relative">
    <div class="d-flex titles__wrap">
      <h2 :class="locale">{{ $t('19_grid_title') }}</h2>
      <p :class="locale">{{ $t('20_grid_text') }}</p>
    </div>
    <div class="row">
      <div class="col-12 col-lg-3">
        <div class="grid-item__wrap">
          <h5 class="d-none d-md-block" v-html="$t('21_grid_title')"></h5>
          <h5 class="d-block d-md-none">{{ $t('22_grid_title') }}</h5>
          <p>{{ $t('23_grid_text') }}</p>
        </div>
      </div>
      <div class="col-12 col-lg-3">
        <div class="grid-item__wrap">
          <h5 class="d-none d-md-block" v-html="$t('24_grid_title')"></h5>
          <h5 class="d-block d-md-none">{{ $t('25_grid_title') }}</h5>
          <p>{{ $t('26_grid_text') }}</p>
        </div>
      </div>
      <div class="col-12 col-lg-3">
        <div class="grid-item__wrap">
          <h5 class="d-none d-md-block" v-html="$t('27_grid_title')"></h5>
          <h5 class="d-block d-md-none">{{ $t('28_grid_title') }}</h5>
          <p>{{ $t('29_grid_text') }}</p>
        </div>
      </div>
    </div>
    <img class="img-fluid grid-1" src="@/assets/img/grid-item-el-1.png" alt="">
    <img class="img-fluid grid-2" src="@/assets/img/grid-item-el-2.png" alt="">
  </div>
</template>

<script>
import {gsap} from "gsap";
import {mapGetters} from "vuex";

export default {
  name: 'Grid',

  computed: mapGetters({
    locale: 'lang/locale',
  }),
  mounted() {
    if (window.innerWidth >= 1200) {
      gsap.timeline().to(".titles__wrap", {
        x: -140,
        scrollTrigger: {
          trigger: ".grid__container",
          start: "top center",
          end: "top 60px",
          scrub: 5,
          id: "scrub"
        }
      })
      gsap.timeline({
        scrollTrigger: {
          trigger: ".grid__container",
          start: "top center",
          end: "bottom 0px",
          scrub: 5,
          id: "scrub"
        }
      })
          .to(".grid-1", {
            x: 50,
            rotate: 0, // Здесь начинаем с начального положения вращения
            duration: 1
          }, "<")
          .to(".grid-1", {
            y: 600,
            rotate: 180,
            duration: 1
          })
      gsap.timeline().to(".grid-2", {
        y: 700,
        rotate: 0,
        scrollTrigger: {
          trigger: ".grid__container",
          start: "top 100px",
          end: "bottom 0px",
          scrub: 5,
          id: "scrub"
        }
      });
    }
  }
}
</script>

<style lang="scss">
.grid-1 {
  position: absolute;
  top: 30%;
  right: -110px;
  transform: rotate(-45deg);
}
.grid-2 {
  position: absolute;
  bottom: -155px;
  right: 70px;
  transform: rotate(140deg);

}
.grid__container {
  margin-top: 50px;
}
.titles__wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 50px;
  position: relative;
  right: -130px;
  h2 {
    font-family: 'Ritzy', sans-serif;
    font-size: 150px;
    font-weight: 400;
    line-height: 197px;
    letter-spacing: 0.17em;
    text-align: left;
    color: #0000FF;

    &.ru {
      font-family: 'Atiba', sans-serif;
    }
  }
  p {
    font-family: 'Ritzy', sans-serif;
    font-size: 45px;
    font-weight: 400;
    line-height: 59px;
    letter-spacing: 0.17em;
    text-align: left;
    color: #0000FF;
    &.ru {
      font-family: 'Atiba', sans-serif;
    }
  }
}
.grid-item__wrap {
  background: linear-gradient(85.96deg, rgba(255, 255, 255, 0) -20.51%, rgba(255, 255, 255, 0.05) 26.82%, rgba(255, 255, 255, 0) 65.65%),
  linear-gradient(0deg, rgba(196, 196, 196, 0.05), rgba(196, 196, 196, 0.05));
  box-shadow: 0px -2px 1px 0px #FFFFFF1A inset;
  border-radius: 10px;
  padding: 40px;
  height: 100%;
  h5 {
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 20px;
  }
  p {
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 200;
    color: #fff;
    margin-bottom: 0;
  }
}
</style>
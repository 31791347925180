<template>
  <footer class="footer container">
    <div class="content">
      <div class="header">
        <div class="left">
          <div class="logo">
            <router-link :to="{ name: 'home', query: { buyer: this.buyer, source: this.source } }">
              <img src="@/assets/img/logo.png" alt="Logo" class="img-fluid">
            </router-link>
          </div>
          <div class="d-flex flex-column">
            <div class="d-flex docs">
              <router-link :to="{ name: 'privacy', query: { buyer: this.buyer, source: this.source } }">
                {{ $t('40_footer_item') }}
              </router-link>
              <router-link @click.native="scrollToTop" :to="{ name: 'terms', query: { buyer: this.buyer, source: this.source } }">
                {{ $t('41_footer_item') }}
              </router-link>
              <router-link @click.native="scrollToTop" :to="{ name: 'agreement', query: { buyer: this.buyer, source: this.source } }">
                {{ $t('42_footer_item') }}
              </router-link>
            </div>
            <div class="d-flex mt-3">
              <p class="mb-0 copyright-text">© 2014 - 2024. {{ $t('43_footer_copyright') }}.</p>
            </div>
          </div>
        </div>
        <div class="right">
          <a href="mailto:lsapphire984@gmail.com">
            <svg width="36" height="39" viewBox="0 0 36 39" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M34.1003 27.8948V10.7948C34.1003 9.21782 32.8272 7.94482 31.2502 7.94482H4.63125C3.05425 7.94482 1.78125 9.21782 1.78125 10.7948V27.8948C1.78125 29.4718 3.05425 30.7448 4.63125 30.7448H31.2502C32.8272 30.7448 34.1003 29.4718 34.1003 27.8948ZM31.6113 10.5858C32.2383 11.2128 31.8962 11.8588 31.5543 12.1818L23.8403 19.2498L31.2502 26.9638C31.4783 27.2298 31.6302 27.6478 31.3642 27.9328C31.1172 28.2368 30.5473 28.2178 30.3003 28.0278L21.9972 20.9408L17.9312 24.6458L13.8842 20.9408L5.58125 28.0278C5.33425 28.2178 4.76425 28.2368 4.51725 27.9328C4.25125 27.6478 4.40325 27.2298 4.63125 26.9638L12.0412 19.2498L4.32725 12.1818C3.98525 11.8588 3.64325 11.2128 4.27025 10.5858C4.89725 9.95882 5.54325 10.2628 6.07525 10.7188L17.9312 20.2948L29.8062 10.7188C30.3382 10.2628 30.9843 9.95882 31.6113 10.5858Z" fill="white"/>
            </svg>
          </a>
          <a href="https://t.me/luna_sapphire" target="_blank">
            <svg width="38" height="39" viewBox="0 0 38 39" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M32.7899 6.24952C32.7899 6.24952 35.8655 5.05015 35.6082 7.96269C35.5235 9.16206 34.7548 13.3603 34.1563 17.9005L32.1059 31.3509C32.1059 31.3509 31.9349 33.3214 30.3967 33.6641C28.8593 34.0061 26.5524 32.4648 26.1249 32.122C25.7829 31.8647 19.7171 28.0093 17.5812 26.1251C16.9827 25.6105 16.2987 24.5829 17.6667 23.3836L26.6371 14.8161C27.6623 13.7886 28.6875 11.3898 24.4157 14.3024L12.4536 22.4407C12.4536 22.4407 11.0864 23.2981 8.52374 22.527L2.9694 20.813C2.9694 20.813 0.918988 19.5281 4.42211 18.2433C12.9666 14.2169 23.4759 10.1049 32.7883 6.24952H32.7899Z" fill="white"/>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data: () => ({
    buyer: "",
    source: "",
  }),
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  },
  mounted() {
    this.buyer = this.$route.query.buyer;
    this.source = this.$route.query.source;
  }
};
</script>

<style lang="scss">
.footer {
  margin-top: 120px;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #fff;
  @media (max-width: $md6+px) {
    font-size: 14px;
    line-height: 21px;
    margin-top: 15px;
  }
  .content {
    border-top: 1px solid #fff;
    padding-top: 26px;
    padding-bottom: 29px;
    position: relative;
    z-index: 2;
    overflow: hidden;
    @media (max-width: $md5+px) {
      padding-top: 15px;
      padding-bottom: 22px;
    }
    .footer-word {
      position: absolute;
      right: 0;
      font-weight: 900;
      font-size: 250px;
      line-height: 0;
      z-index: 0;
      color: #d8d8d8;
    }
    .docs {
      position: relative;
      z-index: 2;
      display: flex;
      gap: 60px;
      margin-top: 20px;
      & > a {
        font-family: 'Inter-ExtraLight', sans-serif;
        font-size: 17px;
        text-decoration: none;
        color: #fff;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          color: #0000FF;
        }
      }
      @media (max-width: $md4+px) {
        flex-direction: column;
        gap: 10px;
      }
    }
    .copyright-text {
      font-family: 'Inter-ExtraLight', sans-serif;
      font-size: 17px;
    }
    .header {
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: space-between;
      @media (max-width: $md5+px) {
        display: block;
      }
      .left {
        display: flex;
        align-items: flex-end;
        gap: 30px;
        @media (max-width: $md5+px) {
          justify-content: space-between;
        }
        &Text {
          cursor: pointer;
          display: flex;
          gap: 30px;
          &-ru {
            flex-direction: column;
            gap: 5px;
            @media (max-width: $md5+px) {
              text-align: right;
            }
            @media (max-width: $md6+px) {
              font-size: 12px !important;
            }
          }
        }
        .logo {
          margin-right: 60px;
          @media (max-width: $md6+px) {
            margin-right: 20px;
          }
          svg {
            cursor: pointer;
            @media (max-width: $md6+px) {
              width: calc(96px * 0.8);
              height: calc(81px * 0.8);
            }
            path {
              transition: all 0.3s;
            }
            &:hover path {
              fill: #0000FF;
            }
          }
        }
      }
      .right {
        display: flex;
        gap: 30px;
        align-items: center;
        @media (max-width: $md5+px) {
          margin-top: 33px;
          justify-content: flex-start;
        }
        svg {
          cursor: pointer;
          path {
            transition: all 0.3s;
          }
          &:hover path {
            fill: #0000FF;
          }
        }
      }
    }
    .info {
      position: relative;
      z-index: 2;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      font-weight: 600;
      @media (max-width: $md5+px) {
        margin-top: 32px;
      }
      .address {
        @media (max-width: $md5+px) {
          margin-top: 0;
        }
      }
    }
    .under {
      position: relative;
      z-index: 2;
      margin-top: 20px;
      display: flex;
      gap: 60px;
      @media (max-width: $md4+px) {
        flex-direction: column;
        gap: 0px;
      }
      @media (max-width: $md5+px) {
        margin-top: 32px;
      }
      .address {
        @media (max-width: $md5+px) {
          margin-top: 0;
        }
      }
    }
  }
}
</style>

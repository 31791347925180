<template>
  <div class="discover__container container position-relative">
    <div class="discover-el-1">
      <h2 :class="locale">{{ $t('8_discover_title') }}</h2>
      <h4 :class="locale">{{ $t('9_discover_text') }}</h4>
    </div>
    <p class="col-12 col-md-6" :class="locale">
      {{ $t('10_discover_text') }}
    </p>
    <div class="d-flex">
      <button type="button" data-bs-toggle="modal" data-bs-target="#joinUsModal" class="btn-default mt-4" :class="locale">{{ $t('2_start_trading') }}</button>
    </div>
    <span class="disc__el el-1"></span>
    <span class="disc__el el-2"></span>
    <span class="disc__el el-3"></span>
  </div>
</template>

<script>
  import {gsap} from "gsap";
  import {mapGetters} from "vuex";

  export default {
    name: 'Discover',
    computed: mapGetters({
      locale: 'lang/locale',
    }),
    mounted() {
      if (window.innerWidth >= 1200) {
        gsap.timeline().to(".discover-el-1", {
          x: 130,
          scrollTrigger: {
            trigger: ".discover__container",
            start: "top center",
            end: "top 250px",
            scrub: 5,
            id: "scrub"
          }
        })
      }
    }
  }
</script>

<style lang="scss">
.btn-default {
  background-color: transparent;
  border: none;
  outline: none;
  &.ru {
    font-family: 'Atiba', sans-serif;
    font-size: 16px;
  }
}
.discover-el-1 {
  right: 130px;
  position: relative;
}
.disc__el {
  position: absolute;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  &.el-1 {
    background-image: url("@/assets/img/disc-el-1.png");
    width: 189px;
    height: 238px;
    bottom: 127px;
    right: -31px;
  }

  &.el-2 {
    background-image: url("@/assets/img/disc-el-2.png");
    width: 30px;
    height: 29px;
    bottom: 103px;
    right: 18%;
  }

  &.el-3 {
    background-image: url("@/assets/img/disc-el-3.png");
    width: 78px;
    height: 78px;
    bottom: 11px;
    right: 34%;
  }
}
.discover__container {
  h2 {
    font-family: 'Ritzy', sans-serif;
    font-size: 150px;
    color: #0000FF;
    &.ru {
      font-family: 'Atiba', sans-serif;
    }
  }
  h4 {
    font-family: 'Ritzy', sans-serif;
    font-size: 45px;
    margin-bottom: 40px;
    color: #0000FF;
    &.ru {
      font-family: 'Atiba', sans-serif;
    }
  }
  p {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    color: #fff;
  }
}
</style>
<template>
  <div class="container-padding first-banner">
    <Menu />
    <div class="banner-text__wrap d-flex justify-content-between">
      <div class="vertical__text">
        <h1 class="animated-el-1">{{ $t('1_title') }}</h1>
        <p>{{ $t('3_sub_title') }}</p>
      </div>
      <div class="d-flex justify-content-center d-block d-md-none">
        <button type="button" data-bs-toggle="modal" data-bs-target="#joinUsModal" class="btn-default" :class="locale">{{ $t('2_start_trading') }}</button>
      </div>
      <div class="vertical__text stroke position-relative">
        <h1 class="animated-el-2">{{ $t('3_part_banner') }}</h1>
        <span class="banner__el el-2"></span>
        <span class="banner__el el-3"></span>
        <span class="banner__el el-4"></span>
        <span class="banner__el el-5"></span>
        <span class="banner__el el-6 animated-el-9"></span>
      </div>
      <div class="img__wrap d-flex align-items-center flex-column">
        <img src="@/assets/img/crystal_main.png" class="img-fluid animated-el-5" alt="crystal">
        <div class="d-flex justify-content-center d-none d-md-flex btn__wrap">
          <button type="button" data-bs-toggle="modal" data-bs-target="#joinUsModal" class="btn-default" :class="locale">{{ $t('2_start_trading') }}</button>
        </div>
      </div>
      <div class="vertical__text stroke position-relative">
        <h1 class="animated-el-3">{{ $t('4_part_banner') }}</h1>
        <span class="banner__el el-1"></span>
        <span class="banner__el el-7"></span>
        <span class="banner__el el-8"></span>
        <span class="banner__el el-9 animated-el-6"></span>
        <span class="banner__el el-10 animated-el-8"></span>
      </div>
      <div class="vertical__text big">
        <h1 class="animated-el-4">{{ $t('3_part_banner') }}</h1>
        <span class="banner__el el-11"></span>
        <span class="banner__el el-12 animated-el-7"></span>
      </div>
    </div>
    <div class="gradient-bottom__bg d-none d-lg-block"></div>
  </div>
</template>

<script>
import Menu from "@/components/Menu";
import { gsap } from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";
import {mapGetters} from "vuex";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'Banner',
  components: {
    Menu
  },
  computed: mapGetters({
    locale: 'lang/locale',
  }),
  mounted() {
    if (window.innerWidth >= 1200) {
      var readyStateCheckInterval = setInterval(function () {
        if (document.readyState === "complete") {
          clearInterval(readyStateCheckInterval)
          gsap.timeline().to(".animated-el-1", {
            y: 230,
            scrollTrigger: {
              trigger: ".banner-text__wrap",
              start: "top center",
              end: "top 20px",
              scrub: 5,
              id: "scrub"
            }
          })
          gsap.timeline().to(".animated-el-2", {
            y: -120,
            scrollTrigger: {
              trigger: ".banner-text__wrap",
              start: "top center",
              end: "top 20px",
              scrub: 5,
              id: "scrub"
            }
          })
          gsap.timeline().to(".animated-el-3", {
            y: 240,
            scrollTrigger: {
              trigger: ".banner-text__wrap",
              start: "top center",
              end: "top 20px",
              scrub: 5,
              id: "scrub"
            }
          })
          gsap.timeline().to(".animated-el-4", {
            y: -200,
            scrollTrigger: {
              trigger: ".banner-text__wrap",
              start: "top center",
              end: "top 20px",
              scrub: 5,
              id: "scrub"
            }
          })
          gsap.timeline().to(".animated-el-5", {
            scale: 1,
            rotate: 0,
            ease: "power1.out",
            duration: 2
          })
          gsap.timeline().to(".animated-el-6", {
            scale: 1,
            y: 50,
            x: 50,
            scrollTrigger: {
              scrub: 5,
              id: "scrub"
            }
          })
        }
      }, 500)
      gsap.timeline().to(".animated-el-7", {
        y: -130,
        scrollTrigger: {
          trigger: ".animated-el-5",
          start: "top center",
          end: "top 50px",
          scrub: 2,
          id: "scrub"
        }
      })
      gsap.timeline().to(".animated-el-8", {
        y: -80,
        scrollTrigger: {
          trigger: ".animated-el-5",
          start: "top center",
          end: "top 20px",
          scrub: 2,
          id: "scrub"
        }
      })
      gsap.timeline().to(".animated-el-9", {
        y: -160,
        scrollTrigger: {
          trigger: ".animated-el-5",
          start: "top center",
          end: "top 70px",
          scrub: 2,
          id: "scrub"
        }
      })
    }
  },
}

</script>

<style lang="scss" scoped>
.gradient-bottom__bg {
  position: absolute;
  bottom: -100px;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  height: 420px;
}
.img__wrap {
  .img-fluid {
    scale: .5;
    transform: rotate(-45deg);
  }
}
.animated-el-1 {
  position: relative;
  bottom: 220px;
}
.animated-el-2 {
  position: relative;
  top: 220px;
}
.animated-el-3 {
  position: relative;
  bottom: 220px;
}
.animated-el-4 {
  position: relative;
  top: 220px;
}
.animated-el-6 {
  scale: .6;

}

.btn__wrap {
  position: relative;
  z-index: 9;
}

.first-banner {
  position: relative;
  background-image: url("@/assets/img/banner-el/banner-bg.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.banner__el {
  position: absolute;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(180deg);
  &.el-1 {
    background-image: url("@/assets/img/banner-el/el-1.png");
    width: 110px;
    height: 116px;
    bottom: 58px;
    right: 38px;
  }
  &.el-2 {
    background-image: url("@/assets/img/banner-el/el-2.png");
    width: 29px;
    height: 30px;
    bottom: 216px;
    right: 115px;
  }
  &.el-3 {
    background-image: url("@/assets/img/banner-el/el-3.png");
    width: 82px;
    height: 86px;
    bottom: 48%;
    right: 135px;
  }
  &.el-4 {
    background-image: url("@/assets/img/banner-el/el-4.png");
    width: 33px;
    height: 31px;
    top: 29%;
    left: 62px;
  }
  &.el-5 {
    background-image: url("@/assets/img/banner-el/el-5.png");
    width: 33px;
    height: 31px;
    top: 16%;
    right: 62px;
  }
  &.el-6 {
    background-image: url("@/assets/img/banner-el/el-6.png");
    width: 78px;
    height: 78px;
    top: 10%;
    left: 22px;
  }
  &.el-7 {
    background-image: url("@/assets/img/banner-el/el-5.png");
    width: 33px;
    height: 31px;
    bottom: 24%;
    left: 62px;
  }
  &.el-8 {
    background-image: url("@/assets/img/banner-el/el-2.png");
    width: 29px;
    height: 30px;
    top: 39%;
    left: 70px;
  }
  &.el-9 {
    background-image: url("@/assets/img/banner-el/el-7.png");
    width: 152px;
    height: 130px;
    top: 25%;
    right: -50px;
  }
  &.el-10 {
    background-image: url("@/assets/img/banner-el/el-1.png");
    width: 110px;
    height: 116px;
    top: 26px;
    right: 61px;
    transform: rotate(-16deg);
  }
  &.el-11 {
    background-image: url("@/assets/img/banner-el/el-2.png");
    width: 29px;
    height: 30px;
    bottom: 78px;
    left: 45px;
  }
  &.el-12 {
    background-image: url("@/assets/img/banner-el/el-3.png");
    width: 82px;
    height: 86px;
    top: 8%;
    left: 37px;
    transform: rotate(-150deg);
  }
}

.banner-text__wrap {
  padding: 100px 80px 0;
}
.vertical__text {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);

  h1 {
    font-family: 'Ritzy', sans-serif;
    font-size: 160px; // Adjust the size as per the design
    color: #0000FF; // Sapphire blue color
    margin: 0;
    font-weight: 400;
    line-height: 210px;
    letter-spacing: 0.17em;
    text-align: right;
  }

  p {
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0.4em;
    color: #0000FF;
    text-align: right;
    margin: 0;
  }
  &.stroke {
    h1 {
      font-size: 320px;
      font-weight: 400;
      line-height: 1;
      letter-spacing: 0.17em;
      text-align: left;

      -webkit-text-stroke: 2px #0000FF; // Sapphire blue color stroke
      color: transparent;
    }
  }

  &.big {
    h1 {
      font-size: 320px;
      font-weight: 400;
      line-height: 1;
      letter-spacing: 0.17em;
      text-align: right;
    }
  }
}
</style>

<template>
  <div class="statistic__container">
    <div class="row">
      <div class="col-12 col-md-4">
        <div class="d-flex position-relative">
          <div class="d-flex flex-column stat-anim-1">
            <span class="number" :class="locale">500+</span>
            <span class="desc">{{ $t('5_statistic_item') }}</span>
            <span class="stat__el el-1"></span>
            <span class="stat__el el-2"></span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="d-flex justify-content-center position-relative">
          <div class="d-flex flex-column">
            <span class="number" :class="locale">5m</span>
            <span class="desc">{{ $t('6_statistic_item') }}</span>
            <span class="stat__el el-3"></span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 ">
        <div class="d-flex justify-content-center justify-content-xl-end position-relative">
          <div class="d-flex flex-column stat-anim-2">
            <span class="number" :class="locale">1,000 +</span>
            <span class="desc">{{ $t('7_statistic_item') }}</span>
            <span class="stat__el el-4"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {gsap} from "gsap";
import {mapGetters} from "vuex";

export default {
  name: 'Statistic',
  computed: mapGetters({
    locale: 'lang/locale',
  }),
  mounted() {
    if (window.innerWidth >= 1200) {
      gsap.timeline().to(".stat-anim-1", {
        x: 130,
        scrollTrigger: {
          trigger: ".statistic__container",
          start: "top center",
          end: "top center",
          scrub: 5,
          id: "scrub"
        }
      })
      gsap.timeline().to(".stat-anim-2", {
        x: -130,
        scrollTrigger: {
          trigger: ".statistic__container",
          start: "top center",
          end: "top center",
          scrub: 5,
          id: "scrub"
        }
      })
    }
  }
}
</script>

<style lang="scss">
.stat-anim-1 {
  position: relative;
  right: 230px;
}
.stat-anim-2 {
  position: relative;
  left: 230px;
}
.stat__el {
  position: absolute;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  &.el-1 {
    display: none;
    background-image: url("@/assets/img/stat-el-1.png");
    width: 78px;
    height: 78px;
    top: -15px;
    right: 150px;
  }
  &.el-2 {
    background-image: url("@/assets/img/stat-el-4.png");
    width: 27px;
    height: 24px;
    bottom: -150px;
    left: -106px;
  }
  &.el-3 {
    background-image: url("@/assets/img/stat-el-3.png");
    width: 42px;
    height: 38px;
    bottom: -180px;
    right: -16px;
  }
  &.el-4 {
    display: none;
    background-image: url("@/assets/img/stat-el-2.png");
    width: 61px;
    height: 46px;
    top: -15px;
    right: -66px;
  }
}
.statistic__container {
  max-width: 970px;
  width: 100%;
  margin: 0 auto;
  padding: 200px 0;
  .number {
    font-family: 'Ritzy', sans-serif;
    font-size: 50px;
    color: #0000FF;
    text-align: center;

    &.ru {
      font-family: 'Atiba', sans-serif;
    }
  }
  .desc {
    font-family: 'Inter-Bold', sans-serif;
    font-size: 16px;
    color: #fff;
    text-align: center;
  }
}
</style>
<template>
  <div class="dropdown-menu" v-if="Object.keys(locales).length > 0">
    <template v-for="(value, key) in locales">
      <a v-if="$i18n.locale !== value" :key="key" class="dropdown-item text-uppercase pointer-event"
          @click.prevent="setLocale(key)"
      >
        <template>
          {{ value }}
        </template>
      </a>
    </template>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { loadMessages } from '@/plugins/i18n'
export default {
  computed: mapGetters({
    locale: 'lang/locale',
    locales: 'lang/locales'
  }),
  methods: {
    setLocale (locale) {
      if (this.$i18n.locale !== locale) {
        loadMessages(locale)
        this.$store.dispatch('lang/setLocale', { locale })
      }
    }
  }
}
</script>

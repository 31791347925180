<template>
  <main>
    <Banner />
    <Statistic />
    <Discover />
    <Benefits />
    <div class="grid_bg">
      <Grid />
      <About />
    </div>
    <Slider />
    <Footer />
    <Modal />
  </main>
</template>

<script>
import Banner from "@/components/index/Banner";
import Statistic from "@/components/index/Statistic";
import Discover from "@/components/index/Discover";
import Benefits from "@/components/index/Benefits";
import Grid from "@/components/index/Grid";
import About from "@/components/index/About";
import Slider from "@/components/index/Slider";
import Footer from "@/components/Footer";
import Modal from "@/components/Modal";
import {gsap} from "gsap";

export default {
  name: 'HomeView',
  layout: 'basic',
  components: {
    Banner,
    Statistic,
    Discover,
    Benefits,
    Grid,
    About,
    Slider,
    Footer,
    Modal
  },
  mounted() {
    if (window.innerWidth >= 1200) {
      gsap.to(".grid_bg", {
        backgroundSize: "80%", // Конечный размер фона
        scrollTrigger: {
          trigger: ".grid_bg",
          start: "top center",
          end: "bottom 0px",
          scrub: 5,
          id: "scrub"
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.grid_bg {
  background-image: url("@/assets/img/grid-bg.png");
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 40%;
}
</style>

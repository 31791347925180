<template>
  <div class="benefit__wrap container d-flex flex-column align-items-start align-items-md-center position-relative">
    <div class="benefit__item item-1" :class="locale">
      <h6 :class="locale" v-html="$t('11_benefits_title')"></h6>
      <p>{{ $t('12_benefits_text') }}</p>
    </div>
    <div class="benefit__item item-2">
      <h6 :class="locale" v-html="$t('13_benefits_title')"></h6>
      <p>{{ $t('14_benefits_text') }}</p>
    </div>
    <div class="top"></div>
    <div class="benefit__item item-3">
      <h6 :class="locale" v-html="$t('15_benefits_title')"></h6>
      <p>{{ $t('16_benefits_text') }}</p>
    </div>
    <div class="benefit__item item-4">
      <h6 :class="locale" v-html="$t('17_benefits_title')"></h6>
      <p>{{ $t('18_benefits_text') }}</p>
    </div>
    <div class="bottom"></div>
  </div>
</template>

<script>
  import {gsap} from "gsap";
  import {mapGetters} from "vuex";

  export default {
    name: 'Benefits',
    computed: mapGetters({
      locale: 'lang/locale',
    }),
    mounted() {
      if (window.innerWidth >= 1200) {
        gsap.timeline().to(".item-1", {
          x: 140,
          scrollTrigger: {
            trigger: ".benefit__wrap",
            start: "top center",
            end: "top 250px",
            scrub: 5,
            id: "scrub"
          }
        })
        gsap.timeline().to(".item-2", {
          x: -208,
          scrollTrigger: {
            trigger: ".benefit__wrap",
            start: "top center",
            end: "top 250px",
            scrub: 5,
            id: "scrub"
          }
        })
        gsap.timeline().to(".item-3", {
          x: 20,
          scrollTrigger: {
            trigger: ".benefit__wrap",
            start: "top center",
            end: "top 50px",
            scrub: 5,
            id: "scrub"
          }
        })
        gsap.timeline().to(".item-4", {
          x: -20,
          scrollTrigger: {
            trigger: ".benefit__wrap",
            start: "top center",
            end: "top 100px",
            scrub: 15,
            id: "scrub"
          }
        })
      }
    }
  }
</script>

<style lang="scss">
.benefit__item {
  position: absolute;
  max-width: 295px;
  width: 100%;
  &:after {
    content: '';
    width: 450px;
    height: 3px;
    background-color: rgba(255, 255, 255, .4);
    display: flex;
  }
  &.ru {
    max-width: 375px;
  }
  &.item-1 {
    top: 200px;
    left: 0;
  }
  &.item-2 {
    top: 89px;
    right: 0;
    text-align: right;
    direction: rtl;
  }
  &.item-3 {
    top: 47.5%;
    left: 0;
  }
  &.item-4 {
    top: 44.5%;
    right: 0;
    direction: rtl;
    text-align: right;
  }
  h6 {
    font-family: 'Ritzy', sans-serif;
    font-size: 30px;
    color: #0000FF;
    direction: ltr;

    &.ru {
      font-family: 'Atiba', sans-serif;
      font-size: 24px;
      span {
        font-family: 'Atiba', sans-serif;
        font-size: 22px;
      }
    }
    span {
      font-family: 'Ritzy', sans-serif;
      font-size: 25px;
      color: #fff;
    }
  }
  p {
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    color: #fff;
    line-height: 30px;
    direction: ltr;
  }
}
.benefit__wrap {
  padding-top: 200px;
  .top {
    width: 561px;
    height: 417px;
    background-image: url("@/assets/img/ben-top-bg.png");
    z-index: 9;
  }
  .bottom {
    background-image: url("@/assets/img/ben-bottom-bg.png");
    width: 609px;
    height: 419px;
  }
}
</style>
<template>
  <div class="modal fade join-us__wrap" id="joinUsModal" tabindex="-1" aria-labelledby="joinUsModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog modal-dialog-centered custom-modal-width">
      <div class="modal-content">
        <div class="modal-body">
          <div v-if="submittedMessage" class="submitted-message">
            {{ submittedMessage }}
          </div>
          <div v-else class="container-fluid">
            <div class="row gx-3">
              <div class="col-md-6 mb-3">
                <input v-model="name" type="text" class="form-control" :class="{'is-invalid': errors.name}" :placeholder="$t('44_form_name')">
                <div v-if="errors.name" class="invalid-feedback">{{ errors.name }}</div>
              </div>
              <div class="col-md-6 mb-3">
                <input v-model="contactChat" type="text" class="form-control" :class="{'is-invalid': errors.contactChat}" :placeholder="$t('45_form_chat')">
                <div v-if="errors.contactChat" class="invalid-feedback">{{ errors.contactChat }}</div>
              </div>
              <div class="col-md-12 mb-3">
                <input v-model="campaign" type="text" class="form-control" :class="{'is-invalid': errors.campaign}" :placeholder="$t('46_form_campaign')">
                <div v-if="errors.campaign" class="invalid-feedback">{{ errors.campaign }}</div>
              </div>
              <div class="col-md-12">
                <input v-model="vertical" type="text" class="form-control" :class="{'is-invalid': errors.vertical}" :placeholder="$t('47_form_vertical')">
                <div v-if="errors.vertical" class="invalid-feedback">{{ errors.vertical }}</div>
              </div>
            </div>
            <div class="d-flex justify-content-end mt-5">
              <button type="button" class="btn submit-btn" @click="submitTg">{{ $t('48_form_submit') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Modal } from 'bootstrap'
export default {
  name: "Modal",
  data: () => ({
    token: '6873750931:AAGMeQCV1xTJI5yLMp5_jU7J-821z6UQhzk',
    groupId: '-4108401403',
    name: '',
    contactChat: '',
    campaign: '',
    vertical: '',
    errors: {},
    submittedMessage: ''
  }),
  methods: {
    validateForm() {
      this.errors = {}; // Reset errors before validation
      let isValid = true;
      const fields = ['name', 'contactChat', 'campaign', 'vertical'];

      fields.forEach(field => {
        if (!this[field] || this[field].length < 3) {
          this.errors[field] = this.$t('49_form_error');
          isValid = false;
        }
      });

      return isValid;
    },
    submitTg() {
      if (!this.validateForm()) {
        return; // Stop submission if validation fails
      }

      let formattingMsg = `С какого сайта: Saphire\nИмя: ${this.name}\nTelegram или Skype: ${this.contactChat}\nКомпания: ${this.campaign}\nВертикаль: ${this.vertical}`;
      let url = `https://api.telegram.org/bot${this.token}/sendMessage?chat_id=${this.groupId}&text=${encodeURIComponent(formattingMsg)}&parse_mode=html`;

      fetch(url, {
        method: "POST",
      }).then((response) => {
        if (response.ok) {
          this.submittedMessage = this.$t('50_form_success');
          setTimeout(() => {
            // this.closeModal();
            // this.submittedMessage = ''; // Reset the message for next use
          }, 5000); // Close modal after 5 seconds
        }
      }).catch((err) => {
        console.log(err);
      });
    },
    closeModal() {
      let modalInstance = new Modal(document.getElementById('joinUsModal'));
      modalInstance.hide();
    }
  }
}
</script>

<style lang="scss" scoped>
.join-us__wrap {
  background: rgba(196, 196, 196, 0.05);
  backdrop-filter: blur(25px);
  .custom-modal-width {
    max-width: 625px;
  }
  .modal-content {
    background-color: #000;
    border-radius: 20px;
    .modal-body {
      padding: 50px 50px 30px;
      .form-control {
        border-radius: 30px;
        border: 1px solid #fff;
        background-color: transparent;
        padding: 14px 30px;
        color: #fff;
        transition: all .3s;
        &::placeholder {
          color: #fff;
          opacity: .5;
        }
        &:focus {
          border: 1px solid #00F;
        }
      }
      .submit-btn {
        border-radius: 30px;
        background: #00F;
        outline: none;
        padding: 14px 30px;
        color: #fff;
        transition: all .3s ease-in-out;
        &:hover {
          opacity: .7;
        }
      }
    }
    .modal-footer {
      padding-bottom: 50px;
    }
  }
}
.submitted-message {
  font-size: 18px;
  color: #fff;
  padding-bottom: 20px;
  text-align: center;
}
</style>
<template>
  <div id="app" class="overflow-hidden main__wrap" :class="locale">
    <div class='loader df aic jcc'>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
    </div>
    <loading ref="loading" />
    <transition name="page" mode="out-in">
      <component :is="layout" v-if="layout" />
    </transition>
  </div>
</template>

<script>
import Loading from '@/components/Loading'
import { gsap, TimelineMax } from 'gsap'
import MotionPathPlugin from "gsap/MotionPathPlugin";
import {mapGetters} from "vuex";
gsap.registerPlugin(MotionPathPlugin);

// Load layout components dynamically.
const requireContext = require.context('@/layouts', false, /.*\.vue$/)

const layouts = requireContext.keys()
    .map(file =>
        [file.replace(/(^.\/)|(\.vue$)/g, ''), requireContext(file)]
    )
    .reduce((components, [name, component]) => {
      components[name] = component.default || component
      return components
    }, {})

export default {
  el: '#app',

  components: {
    Loading
  },

  data: () => ({
    layout: null,
    defaultLayout: 'default'
  }),
  computed: mapGetters({
    locale: 'lang/locale',
  }),
  metaInfo () {
    const appName = 'Sapphire'

    return {
      title: appName,
      titleTemplate: `${appName}`
    }
  },

  mounted () {
    this.$loading = this.$refs.loading
    var circle = document.querySelectorAll('.circle');

    var tl = new TimelineMax({ repeat: -1, yoyo: true });

    tl.staggerFromTo(circle, 1, {
      autoAlpha: 0,
      scale: .1
    }, {
      autoAlpha: 1,
      scale: 1,
      motionPath: {
        type: 'soft',
        path: [
          { x:  -50, y: -50 }, // P1
          { x: -100, y:   0 }, // P2
          { x:  -50, y:  50 }, // P3
          { x:   50, y: -50 }, // P4
          { x:  100, y:   0 }, // P5
          { x:   50, y:  50 }, // P6
          { x:    0, y:   0 }  // P7
        ]
      },
      stagger: .1
    });

    var readyStateCheckInterval = setInterval(function() {
      if (document.readyState === "complete") {
        clearInterval(readyStateCheckInterval);
        document.querySelector('.loader ').style.display = 'none'
      }
    }, 10);
  },

  methods: {
    /**
     * Set the application layout.
     *
     * @param {String} layout
     */
    setLayout (layout) {
      if (!layout || !layouts[layout]) {
        layout = this.defaultLayout
      }

      this.layout = layouts[layout]
    }
  }
}
</script>

<style lang="scss">
@import "styles/media";
.loader {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: linear-gradient(to bottom right, #00004b, #000);
  z-index: 9999;
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 5px 2px rgba(255,255,255,0.5);
}
</style>

<template>
  <div class="slider__container container">
    <div class="row">
      <div class="offset-lg-6 col-lg-6 col-12">
        <div class="d-flex flex-column">
          <h2 :class="locale">{{ $t('32_slider_title') }}</h2>
          <h5 :class="locale">{{ $t('33_slider_text') }}</h5>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="slider__wrap col-12 col-lg-6">
          <p class="fw-bold">{{ $t(slides[currentSlide].title ) }}</p>
          <p class="m-height">{{ $t(slides[currentSlide].desc) }}</p>
          <div class="d-flex">
            <span>#Affiliate</span>
            <span>#Strategy</span>
          </div>
          <div class="d-flex d-lg-none">
            <span class="prev-slide ms-2" @click="prevSlide">
              <svg width="58" height="16" viewBox="0 0 58 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.3333 15.6724C19.3333 14.8911 17.5619 13.7245 15.7688 12.7452C13.4633 11.4817 10.7083 10.3792 7.54967 9.5379C5.18133 8.90718 2.31033 8.30173 8.78946e-08 8.30173M8.78946e-08 8.30173C2.31033 8.30173 5.18375 7.69628 7.54967 7.06556C10.7083 6.2232 13.4633 5.12076 15.7688 3.85931C17.5619 2.87901 19.3333 1.71023 19.3333 0.931044M8.78946e-08 8.30173L58 8.30173" stroke="white"/>
              </svg>
            </span>
            <span class="next-slide ms-2" @click="nextSlide">
              <svg width="58" height="16" viewBox="0 0 58 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M38.6667 0.327575C38.6667 1.10887 40.4381 2.27554 42.2312 3.25479C44.5367 4.51834 47.2917 5.62078 50.4503 6.4621C52.8187 7.09282 55.6897 7.69827 58 7.69827M58 7.69827C55.6897 7.69827 52.8162 8.30372 50.4503 8.93444C47.2917 9.7768 44.5367 10.8792 42.2312 12.1407C40.4381 13.121 38.6667 14.2898 38.6667 15.069M58 7.69827L-2.22953e-06 7.69826" stroke="white"/>
              </svg>
            </span>
          </div>
        </div>
        <div class="d-none justify-content-end arrows__wrap d-lg-flex">
         <span class="prev-slide me-2" @click="prevSlide">
            <svg width="58" height="16" viewBox="0 0 58 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.3333 15.6724C19.3333 14.8911 17.5619 13.7245 15.7688 12.7452C13.4633 11.4817 10.7083 10.3792 7.54967 9.5379C5.18133 8.90718 2.31033 8.30173 8.78946e-08 8.30173M8.78946e-08 8.30173C2.31033 8.30173 5.18375 7.69628 7.54967 7.06556C10.7083 6.2232 13.4633 5.12076 15.7688 3.85931C17.5619 2.87901 19.3333 1.71023 19.3333 0.931044M8.78946e-08 8.30173L58 8.30173" stroke="white"/>
            </svg>
          </span>
          <div class="next-slide ms-2" @click="nextSlide">
            <svg width="58" height="16" viewBox="0 0 58 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M38.6667 0.327575C38.6667 1.10887 40.4381 2.27554 42.2312 3.25479C44.5367 4.51834 47.2917 5.62078 50.4503 6.4621C52.8187 7.09282 55.6897 7.69827 58 7.69827M58 7.69827C55.6897 7.69827 52.8162 8.30372 50.4503 8.93444C47.2917 9.7768 44.5367 10.8792 42.2312 12.1407C40.4381 13.121 38.6667 14.2898 38.6667 15.069M58 7.69827L-2.22953e-06 7.69826" stroke="white"/>
            </svg>
          </div>
        </div>
        <div class="d-none flex-wrap d-lg-flex slide-items">
          <div class="thumbnail-container" ref="thumbnailsContainer">
            <div class="thumbnail-wrapper">
              <div
                  v-for="(thumb, index) in thumbnails"
                  :key="'thumb-' + index"
                  class="slide-item thumb"
                  @click="changeSlide(index)"
                  :class="{ active: index === currentSlide }"
              >
                <img :src="require('@/assets/img/slider/' + thumb)" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <img :src="require('@/assets/img/slider/' + slides[currentSlide].img)" class="img-fluid h-100" alt="">
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters} from "vuex";

  export default {
    data() {
      return {
        currentSlide: 0,
        slides: [
          {
            title: '34_slider_title',
            desc: '35_slider_text',
            img: 'slide-item-3.png',
          },
          {
            title: '36_slider_title',
            desc: '37_slider_text',
            img: 'slide-item-2.png',
          },
          {
            title: '38_slider_title',
            desc: '39_slider_text',
            img: 'slide-item-1.png',
          },
        ],
        thumbnails: [
          'thumb-item-1.png',
          'thumb-item-2.png',
          'thumb-item-3.png',
        ]
      };
    },
    computed: mapGetters({
      locale: 'lang/locale',
    }),
    methods: {
      scrollThumbnails(direction) {
        const container = this.$refs.thumbnailsContainer
        const scrollAmount = container.clientWidth

        if (direction === 'next') {
          container.scrollLeft += scrollAmount;
        } else {
          container.scrollLeft -= scrollAmount;
        }
      },
      changeSlide(index) {
        this.currentSlide = index;
      },
      nextSlide() {
        this.scrollThumbnails('next')
        this.currentSlide = (this.currentSlide + 1) % Object.keys(this.slides).length; // перейти к следующему слайду
      },
      prevSlide() {
        this.scrollThumbnails('prev')
        this.currentSlide = (this.currentSlide - 1 +Object.keys(this.slides).length) % Object.keys(this.slides).length; // перейти к предыдущему слайду
      }
    }
  }
</script>

<style lang="scss">
.thumbnail-container {
  overflow-x: auto; // Добавляем горизонтальный скролл
  white-space: nowrap; // Элементы внутри будут располагаться в одну строку
  display: flex; // Для поддержки flexbox
  flex-wrap: nowrap; // Отключаем перенос элементов
  // Убедитесь, что контейнер ограничен в ширине или по крайней мере шире, чем экран
  width: 500px; // Может потребоваться корректировка
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none; // Скрываем скроллбар для webkit-браузеров
  }
}

.thumbnail-wrapper {
  display: flex;
  flex-wrap: nowrap; // Элементы будут в одну строку
  width: 900px;
}

.thumb {
  width: calc(50% - 4px); // 50% ширины родителя минус маржин между thumb
  cursor: pointer;
  display: inline-block; // Размещает элементы в строку, как слова
  position: relative;
  margin: 0 40px;
  opacity: .7;
  &:first-child {
    margin-left: 0;
  }

  img {
    width: 180px;
    opacity: 1;
    height: auto;
  }

  &.active {
    opacity: 1;
  }
}

.arrows__wrap {
  position: relative;
  right: 31%;
}

.img-thumbnail {
  max-width: 100px; // или другой размер, который вам нужен
  margin-right: 10px; // для отступа между миниатюрами
}
.slider__container {
  padding-top: 200px;
  .slide-items {
    margin-top: 75px;
    position: relative;
  }
  .next-slide, .prev-slide {
    cursor: pointer;
  }
  .offset-lg-6 {
    h2 {
      font-family: 'Ritzy', sans-serif;
      font-size: 150px;
      font-weight: 400;
      line-height: 197px;
      letter-spacing: 0.17em;
      text-align: left;
      color: #0000FF;
      &.ru {
        line-height: 1;
        font-family: "Atiba", sans-serif;
        font-size: 100px;
      }
    }
    h5 {
      font-family: 'Ritzy', sans-serif;
      font-size: 45px;
      font-weight: 400;
      line-height: 59px;
      letter-spacing: 0.15em;
      text-align: left;
      color: #0000FF;
      margin-bottom: 80px;
      &.ru {
        font-family: "Atiba", sans-serif;
        font-size: 35px;
      }
    }
  }
  .slider__wrap {
    p {
      font-family: "Inter-ExtraLight", sans-serif;
      font-size: 16px;
      font-weight: 200;
      line-height: 24px;
      letter-spacing: 0.03em;
      text-align: left;
      max-width: 525px;
      width: 100%;
      color: #fff;
      margin-top: 50px;
      min-height: 48px;
      &.m-height {
        min-height: 120px;
      }
    }
    .d-flex {
      margin-top: 50px;
      span {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.03em;
        text-align: left;
        color: #fff;
        &:last-child {
          margin-left: 24px;
        }
      }
    }
  }
}
</style>
<template>
  <div>
    <transition name="bounce">
      <Child/>
    </transition>
  </div>
</template>

<script>
import Child from "@/components/Child";
export default {
  components: {
    Child,
  },
}
</script>

